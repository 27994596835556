import { createAction, props } from "@ngrx/store";
import { DiscussionCategory } from "../../domain/entities/discussion-category.entity";
import {
  CreateDiscussionResponseDTO,
  DiscussionDTO
} from "../../application/dtos/discussion.dto";
import { Discussion } from "../../domain/entities/discussion.entity";

// Load Discussion Categories
export const loadDiscussionCategories = createAction(
  "[Load Discussion Categories] Load Discussion Categories"
);
export const loadDiscussionCategoriesSuccess = createAction(
  "[Load Discussion Categories] Load Discussion Categories Success",
  props<{ categories: DiscussionCategory[] }>()
);
export const loadDiscussionCategoriesFailure = createAction(
  "[Load Discussion Categories] Load Discussion Categories Failure",
  props<{ error: any }>()
);

// Create Discussion
export const createDiscussion = createAction(
  "[Create Discussion] Create Discussion",
  props<{ discussion: DiscussionDTO }>()
);
export const createDiscussionSuccess = createAction(
  "[Create Discussion] Create Discussion Success",
  props<{ discussion: CreateDiscussionResponseDTO }>()
);
export const createDiscussionFailure = createAction(
  "[Create Discussion] Create Discussion Failure",
  props<{ error: any }>()
);

// Load Discussions
export const loadDiscussions = createAction("[Load Discussions] Load Discussions");
export const loadDiscussionsSuccess = createAction(
  "[Load Discussions] Load Discussions Success",
  props<{ discussions: Discussion[] }>()
);
export const loadDiscussionsFailure = createAction(
  "[Load Discussions] Load Discussions Failure",
  props<{ error: any }>()
);

// Activate Discussion
export const activateDiscussion = createAction(
  "[Activate Discussion] Activate Discussion",
  props<{ discussionId: string }>()
);
export const activateDiscussionSuccess = createAction(
  "[Activate Discussion] Activate Discussion Success"
);
export const activateDiscussionFailure = createAction(
  "[Activate Discussion] Activate Discussion Failure",
  props<{ error: any }>()
);

// Deactivate Discussion
export const deactivateDiscussion = createAction(
  "[Deactivate Discussion] Deactivate Discussion",
  props<{ discussionId: string }>()
);
export const deactivateDiscussionSuccess = createAction(
  "[Deactivate Discussion] Deactivate Discussion Success"
);
export const deactivateDiscussionFailure = createAction(
  "[Deactivate Discussion] Deactivate Discussion Failure",
  props<{ error: any }>()
);

// Delete Discussion
export const deleteDiscussion = createAction(
  "[Delete Discussion] Delete Discussion",
  props<{ discussionId: string }>()
);
export const deleteDiscussionSuccess = createAction(
  "[Delete Discussion] Delete Discussion Success",
  props<{ discussionId: string }>()
);
export const deleteDiscussionFailure = createAction(
  "[Delete Discussion] Delete Discussion Failure",
  props<{ error: any }>()
);


// Get Unread Discussion Count
export const getUnreadDiscussionCount = createAction(
  "[Get Unread Discussion Count] Get Unread Discussion Count"
);
export const getUnreadDiscussionCountSuccess = createAction(
  "[Get Unread Discussion Count] Get Unread Discussion Count Success",
  props<{ count: number }>()
);
export const getUnreadDiscussionCountFailure = createAction(
  "[Get Unread Discussion Count] Get Unread Discussion Count Failure",
  props<{ error: any }>()
);

// Créer une demande (étape 1)
export const createDraftRequest = createAction(
  '[Message] Create Draft Request',
  props<{
    requestsubject: string;
    requeststatus: string;
    messagestatus: string;
    messagedescription: string;
    username: string;
    createdby: string;
    priority: string;
    requestcategory_id: string;
  }>()
);

export const createDraftRequestSuccess = createAction(
  '[Message] Create Draft Request Success',
  props<{ response: any }>() // Remplacez `any` par un type spécifique si nécessaire
);

export const createDraftRequestFailure = createAction(
  '[Message] Create Draft Request Failure',
  props<{ error: any }>()
);

// Créer un média (étape 2)
export const createMedia = createAction(
  '[Message] Create Media',
  props<{ name: string; size: number }>()
);

export const createMediaSuccess = createAction(
  '[Message] Create Media Success',
  props<{ mediaId: string }>()
);

export const createMediaFailure = createAction(
  '[Message] Create Media Failure',
  props<{ error: any }>()
);

// Ajouter un attachement (étape 3)
export const addAttachment = createAction(
  '[Message] Add Attachment',
  props<{
        documentName: string;
        version: string;
        userId: string;
        messageId: string;
        content: string;
        id: string;
  }>()
);

export const addAttachmentSuccess = createAction(
  '[Message] Add Attachment Success',
  props<{ attachmentId: string }>() // Remplacez `any` par un type spécifique si nécessaire
);

export const addAttachmentFailure = createAction(
  '[Message] Add Attachment Failure',
  props<{ error: any }>()
);

// Créer le message final (étape 5)
export const createFinalMessage = createAction(
  '[Message] Create Final Message',
  props<{
    requestid: string;
    messageid: string;
    requestsubject: string;
    requeststatus: string;
    messagedescription: string;
    username: string;
    createdby: string;
    priority: string;
    requestcategory_id: string;
    mediaIds: string[];
  }>()
);

export const createFinalMessageSuccess = createAction(
  '[Message] Create Final Message Success',
  props<{ response: any }>() // Remplacez `any` par un type spécifique si nécessaire
);

export const createFinalMessageFailure = createAction(
  '[Message] Create Final Message Failure',
  props<{ error: any }>()
);