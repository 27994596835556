import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DiscussionState } from "../reducers/discussion.reducers";

export const selectDiscussionState =
  createFeatureSelector<DiscussionState>("discussion");

export const selectAllDiscussionCategories = createSelector(
  selectDiscussionState,
  (state: DiscussionState) => state.categories
);

export const selectAllDiscussions = createSelector(
  selectDiscussionState,
  (state: DiscussionState) => state.discussions
);

export const selectDiscussionError = createSelector(
  selectDiscussionState,
  (state: DiscussionState) => state.error
);
export const selectDiscussionsLoading = createSelector(
  selectDiscussionState,
  (state: DiscussionState) => state.loading
);

export const selectResponse = createSelector(
  selectDiscussionState,
  (state: DiscussionState) => state.response
);

export const selectUnreadDiscussionCount = createSelector(
  selectDiscussionState,
  (state: DiscussionState) => state.count
);

export const selectMediaIds = createSelector(
  selectDiscussionState,
  (state: DiscussionState) => state.mediaIds
);

export const selectLastMediaId = createSelector(
  selectDiscussionState,
  (state) => state.mediaIds.length > 0 ? state.mediaIds[state.mediaIds.length - 1] : null
);

export const selectMessageId = createSelector(
  selectDiscussionState,
  (state: DiscussionState) => state.messageId
);

export const selectRequestId = createSelector(
  selectDiscussionState,
  (state: DiscussionState) => state.requestId
);